import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Fixed } from "@components/feature-list";
import StartButton from "@components/StartButton";

const EftposCards = ({ getStarted, reverse }) => {
 
  const images = useStaticQuery(graphql`
    query {
      xs: file(name: { eq: "physical-card" }) {
        sharp: childImageSharp {
          fixed(width: 438) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      sm: file(name: { eq: "physical-card" }) {
        sharp: childImageSharp {
          fixed(width: 300) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      md: file(name: { eq: "physical-card" }) {
        sharp: childImageSharp {
          fixed(width: 560) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `);

  const sources = [
    images.xs.sharp.fixed,
    {
      ...images.sm.sharp.fixed,
      media: `(min-width: 640px AND max-width: 767px)`,
    },
    {
      ...images.md.sharp.fixed,
      media: `(min-width: 768px)`,
    },
  ];

  return (
    <Fixed
      id="physical-eftpos"
      description={<Description getStarted={!!getStarted}/>}
      image={sources}
      reverse={!reverse} />
  );
}

const Description = ({ getStarted }) => (
  <div>

    <div>
      <h2>
        <span className="block text-base text-highlight font-semibold tracking-wide uppercase">
          Eftpos Cards
        </span>

        <span className="block text-3xl font-bold tracking-tight text-gray-900 mt-1">
          Physical Gift Card
        </span>
      </h2>
      <p className="mt-4 text-lg text-gray-500">
        WAIVPAY is Australia’s largest supplier of gift card programs to independent shopping 
        centres.
      </p>
      <p className="mt-4 text-lg text-gray-500">
        Your brand is powerful, and the best way to incentivise and reward is by providing your own 
        personalised branded gift card. Beautifully designed with your chosen artwork, WAIVPAY’s
        EFTPOS cards increase brand awareness, cements loyalty and increases sales. Each card can
        store value from $5 to $4,999.
      </p>

      <p className="mt-4 text-lg text-gray-500">
        Pair with WAIVPAY Qube for deep insights into customer behaviours and traffic analytics.
      </p>
      {
        getStarted &&
          <div className="mt-6">
            <StartButton to="/giftcards" />
          </div>
      }
    </div>
  </div>
);

export default EftposCards;
